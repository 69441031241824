@import "../../../../common//assets/scss//main.scss";

.your-profile {
  // background-color: white;
  padding: 3%;
  border-radius: 20px;
  height: auto;
  max-width: 100%;
  // margin-top: 10%;
}

@media screen and (min-width:320px) and (max-width:420px) {
  .profile-picture {
    width: 60px !important;
    height: 60px !important;
    font-size: 20px !important;
  }
}

@media (max-width: 768px) {

  /* Adjust the max-width as needed for your mobile breakpoint */
  .your-profile {
    background: url(./img/Vector145.png) no-repeat center center;
    background-size: cover;
    /* Adjust background size for mobile if needed */
    background-size: contain;
    /* Add any other mobile-specific styles here */
  }

  .userprofilefull {
    background-color: #f0f0f5;
  }
}

.dark-mode .textinputprofile input {
  color: rgb(167, 33, 33);
}

.theme--dark .textinputprofile .MuiOutlinedInput-root .css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: #9E9E9E;
}

.theme--dark .textinputprofile .MuiOutlinedInput-root .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: #9E9E9E;
}

.theme--dark .your-profile {
  background-color: #02101F;
  color: white;
  box-shadow: none;
}

.theme--dark .userprofilefull {
  background-color: #0a1929 !important;
  color: white !important;
  height: 100vh !important;
}


.arrow-iconprofile {
  float: left;
  width: 25px !important;
  height: 25px !important;
  margin-right: 1%;
  margin-bottom: auto;
}

.arrow-textprofile {
  padding-top: 10%;
  margin-bottom: 10%;
  margin-left: 10px;
}

.profile-imageupload {
  display: flex;
  justify-content: center;
}

.profile-picture {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #DCE8FF;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-bottom: 10%;
  border: white;
}

.profile-initial {
  font-size: 32px;
  color: #666;
  margin-bottom: 5px;
  font-weight: bold;
  font-family: 'SegoeUI';
}

.edit-icon {
  margin-top: 30%;
  background-color: #eed6aa;
  padding: 2px;
  margin-top: 90px;
  border-radius: 5px;

}

.text-success {
  color: #28a745 !important;
  text-transform: none;
  font-size: 14px;
  margin-top: 14px;
  font-weight: 600;
}

.custom-clear-icon {
  background-color: #B0B4C0 !important;
  border-radius: 50%;
  color: white !important;
  padding: 1px !important;
}

.form-group-spacing {
  margin-bottom: 1rem;

}

.textinputprofile {
  font-weight: 600;
  font-size: 16px;
  border-radius: 10px !important;
  font-family: "SegoeUI-Light";
  // padding: 5%;
}


.textinputprofile .MuiOutlinedInput-root {
  border-radius: 10px !important;
  margin-bottom: 7% !important;
  font-family: "SegoeUI-Light";
}

.theme--dark .textinputprofile .MuiOutlinedInput-root {
  border-color: #D1D4DB;
  color: rgb(189, 189, 189);
  font-weight: 400;
}

.theme--dark .textinputprofile .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #D1D4DB;
  color: white;
  font-weight: 300;
}

.theme--dark .textinputprofile .MuiInputLabel-root {
  color: gray;
}

.Update-profile {
  background-color: #9FE871 !important;
  color: #173303 !important;
  border: #173303 !important;
  width: 100% !important;
  margin-top: 5%;
  font-weight: 600;
  margin-bottom: 5% !important;
  font-family: "SegoeUI";
  border-radius: 12px;
}

@media only screen and (min-width: 768px) and (max-width: 2440px) {
  .Update-profile {
    background-color: #9FE871 !important;
    color: #173303 !important;
    font-size: larger !important;
    border: #173303 !important;
    width: 50% !important;
    margin-top: 5%;
    // font-weight: 600;
    margin-bottom: 5% !important;
    padding: 1.5% !important;
    font-family: "SegoeUI-Light";
  }

  .textinputprofile .MuiInputBase-input {
    font-weight: 600;
    font-size: 16px;
    border-radius: 10px !important;
    padding: 4% !important;
    font-family: "SegoeUI-Light";
  }

  .theme--dark .textinputprofile .MuiOutlinedInput-root .css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input {
    opacity: 1;
    -webkit-text-fill-color: #9E9E9E;
  }
}