.arrow-tablebooking-text {
    padding-top: 5%;
    font-family:   "SegoeUI-SemiBold";
}

.theme--dark .yourorder-fk {
    background-color: #0a1929 !important;
    color: white;
    height: 100vh !important;
}

.button-reorder {
    background-color: #006B04 !important;
    color: white !important;
    border-color: #006B04 !important;
    padding: 6px 18px !important;
    font-family: "SegoeUI";
}

.card-order {

    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
}

.theme--dark .card-order {
    background-color: #02111f;
}

.waiting-messagebutn {
    background-color: #EFEFEF !important;
    color: #686868 !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    border-radius: 4px !important;
    padding: 5px 10px !important;
    float: right !important;
    font-family: "SegoeUI";
}

.view-menutxt {
    color: #006B04;
    float: right;
    font-weight: 500;
}

.ptag-order {
    float: left;
    color: #B0B4C0;
    flex: 1;
    font-size: 14px;
}

.order-data {
    color: #B0B4C0;
    border-bottom: 1px solid #B0B4C0;
    border-top: 1px solid #B0B4C0;
    padding: 3%;
    margin-bottom: 5% !important;
    font-family: "SegoeUI";
}


// .order-imagefirst {
//     width: 50px;
//     height: 50px;
//     margin-right: 10px;
// }

.ploycon-image {
    width: 12px;
    height: 12px;
    margin-right: 10px;
}

.item-data {
    border-top: 1px solid #B0B4C0;
    padding: 1%;
}

.order-container {
    display: flex;
    flex-direction: column;
}

.order-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.order-imagefirst {
    margin-right: 10px;
    width: 65px;
    height: 65px;
}

.restaurant-yourname {
    flex-grow: 1;
    font-size: 12px !important;
    font-weight: 600;
    margin: 0;
    font-family: "SegoeUI-SemiBold";
}

.buttonorder-divbtn {
    text-align: end;
}

.buttonorder-viewmenu {
    display: flex;
    justify-content: space-between;
}

.search-feild {
    width: 100%;

}

.search-feild .MuiInputLabel-root {
    color: gray;
}

.search-feild .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: none !important;
    border: none !important;
    // background-color:;
}

.search-feild .MuiOutlinedInput-root {
    border-color: #006B04;
    background-color: #F8F8F8 !important;
}

.theme--dark .search-feild .MuiOutlinedInput-root {
    border-color: #006B04;
}

.theme--dark .search-feild .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: #006B04;
}

.theme--dark .search-feild .MuiInputLabel-root {
    color: gray;
}

.textfield-search {
    margin: 5% 0 5% 0 !important;
}

.searchicon {
    color: #006B04;
}

.reorder-image {
    height: auto;
    margin-bottom: 3px !important;
    margin-right: 7px;
}

.amount-color {
    color: #006B04;
    font-family: "SegoeUI";
   
}
.button-viewbill{
    background-color: #F4F4F4 !important;
    color: #006B04!important;
    border-color: #F4F4F4  !important;
    padding: 3px 12px !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    width: 100% !important;
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
    .search-feild {
        width: 100% !important;
    }

  
    .button-viewbill{
        background-color: #F4F4F4 !important;
        color: #006B04!important;
        border-color: #F4F4F4  !important;
        padding: 3px 12px !important;
        font-weight: 500 !important;
        font-size: 18px !important;
        width: 100% !important;
    }
    .restaurant-yourname {
        flex-grow: 1;
        font-size: 14px !important;
        font-weight: 600;
        margin: 0;
        font-family: "SegoeUI-SemiBold";
    }

    .restaurant-name {
        flex-grow: 1;
        font-size: 16px;
        font-size: medium !important;
        font-family: "SegoeUI-SemiBold";
    }

    .waiting-messagebutn {
        padding: 5px 8px !important;
        font-family: "SegoeUI";
    }

    .arrow-tablebooking-text {
        padding-top: 5%;
        // font-size: x-large;
        font-family: "SegoeUI-SemiBold";
    }

    .textfield-search {
        margin-bottom: 5% !important;
        margin-top: 5% !important;
    }
    .order-data{
        color: #B0B4C0;
    border-bottom: none !important; 
    border-top: 1px solid #e8e9eb;
    padding: 3%;
    margin-bottom: 5% !important;
    font-family: "SegoeUI";
    
    }
    .amount-color {
        color: #006B04;
        float: right;
        font-family: "SegoeUI";
    }
}
@media only screen and (min-width: 768px) and (max-width: 1100px) {
    .button-reorder {
        background-color: #006B04 !important;
        color: white !important;
        border-color: #006B04 !important;
        padding: 3px 2px !important;
        font-weight: 500 !important;
        font-size: 18px !important;
        width: 100% !important;
        font-family: "SegoeUI";
    }
}
@media only screen and (min-width: 1100px) and (max-width: 1600px) {
    .button-reorder {
        background-color: #006B04 !important;
        color: white !important;
        border-color: #006B04 !important;
        padding: 3px 12px !important;
        font-weight: 500 !important;
        font-size: 18px !important;
        width: 100% !important;
        font-family: "SegoeUI";
    }
}

.arrow-iconyourorder{
    float: left;
    width: 25px !important;
    height: 25px !important;
    margin-right: 1%;
    margin-bottom: auto;
}

  .paginationorder {
    display: flex;
    justify-content: end;
    align-items: end;
    margin-top: 20px;
    background-color: white;
    margin-bottom: 0px !important;
    button {
      background-color: white;
      color: black;
      padding: 5px 16px;
      border: none;
      border-radius: 4px;
      border: 1px solid #AFAFAF;
      cursor: pointer;
      margin: 0 5px;
      font-family: "SegoeUI";
      &:disabled {
        background-color: white;
        cursor: not-allowed;
        border: 1px solid #AFAFAF;
        color: black;
        font-family: "SegoeUI";
      }
    }
  
    span {
      margin: 0 10px;   
      font-size: 16px;
      border: 1px solid #AFAFAF;
      padding: 5px 12px;
      border-radius: 5px;
    }
  }

  .theme--dark .paginationorder{
    display: flex;
    justify-content: end;
    align-items: end;
    margin-top: 20px;
    background-color: rgb(8, 21, 34);
    margin-bottom: 0px !important;
    button {
      background-color: white;
      color: black;
      padding: 5px 16px;
      border: none;
      border-radius: 4px;
      border: 1px solid #AFAFAF;
      cursor: pointer;
      margin: 0 5px;
      font-family: "SegoeUI";
      &:disabled {
        background-color: white;
        cursor: not-allowed;
        border: 1px solid #AFAFAF;
        color: black;
        font-family: "SegoeUI";
      }
    }
  
    span {
      margin: 0 10px;   
      font-size: 16px;
      border: 1px solid #AFAFAF;
      padding: 5px 12px;
      border-radius: 5px;
    }
  }