.booktable-button {
    float: right;
    background-color: #1E7300 !important;
    color: white !important;
    border: 1px solid #1E7300 !important;
    padding: 2px 4px;
}

.theme--dark .yourorder-fk {
    background-color: #0a1929 !important;
    color: white;
    height: auto;
    padding-bottom: 20px;
}

.pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    border-radius: 25px;
}

.pagination {
    display: flex;
    justify-content: end;
    align-items: end;
    margin-top: 20px;

    button {
        background-color: white;
        color: black;
        padding: 5px 16px;
        border: none;
        border-radius: 4px;
        border: 1px solid #AFAFAF;
        cursor: pointer;
        margin: 0 5px;
        font-family: "SegoeUI";

        &:disabled {
            background-color: white;
            cursor: not-allowed;
            border: 1px solid #AFAFAF;
            color: black;
            font-family: "SegoeUI";
        }
    }

    span {
        margin: 0 10px;
        font-size: 16px;
        border: 1px solid #AFAFAF;
        padding: 5px 12px;
        border-radius: 5px;
    }
}

.pagination-button {
    padding: 8px 16px;
    margin: 0 10px;
    border: 2px solid #AFAFAF;
    font-size: 18px;
    background-color: white;
    border-radius: 10px;
    font-weight: 700;
    color: black;
    cursor: pointer;
}

.pagination-button.active {
    border-radius: 10px;
    font-size: 18px;
    border: 2px solid #AFAFAF;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
}

.pagination-button:hover {
    background-color: #242323;
    color: white;
}

.tablemodalbooking-op {
    .tablebooking-arrow {
        .arrowtop-icons {
            padding: 6px 6px;
            border-radius: 13px;
            margin-bottom: 4px;
            margin-right: 6px;
            background-color: #ACACAC;
        }
    }
}

.button-optionstablebooking {
    display: flex;
    flex: 1;
    justify-content: space-around;
    margin-top: 10%;
    margin-bottom: 5%;
    overflow-x: auto;
    /* Enables horizontal scrolling */
    overflow-y: hidden;
    /* Prevents vertical scrolling */
    text-wrap: nowrap;
    -ms-overflow-style: none;
    /* Hide scrollbar in Internet Explorer and Edge */
    scrollbar-width: none;
    /* Hide scrollbar in Firefox */
}

.button-optionstablebooking::-webkit-scrollbar {
    display: none;
    /* Hides scrollbar in Chrome, Safari, and Opera */
}

.tablearrow-icon {
    float: left;
    margin-right: 5%;
    width: 9%;
    height: 9%;
}

.button-all {
    padding: 5px 10px !important;
    background-color: white !important;
    color: black;
    font-weight: 500;
    font-size: 14px !important;
    border: none;
    margin: 5px;
    box-shadow: 1px 1px 3px #c3c3c3;
    font-family: "SegoeUI-SemiBold";
}

.theme--dark .button-all {
    padding: 5px 10px !important;
    background-color: white !important;
    color: black;
    font-weight: 500;
    font-size: 14px !important;
    border: none;
    margin: 5px;
    font-family: "SegoeUI-SemiBold";
    box-shadow: 0px 0px 0px black;
}

.bottom-modaladdress {
    position: fixed;
    top: 25%;
    left: 12.5%;
    width: 100%;
    margin: 0 auto;
    animation: fade-in 0.5s forwards;
    z-index: 1050;
}

.bottom-modaladdressdark {
    background-color: #02111f;
    position: fixed;
    bottom: -100%;
    left: 0;
    right: 0;
    margin: auto;
    animation: slide-up 0.5s forwards;
    color: white;
    border-top-left-radius: 10%;
    border-top-right-radius: 10%;
}

@keyframes slide-up {
    from {
        bottom: -100% !important;
    }

    to {
        bottom: 0% !important;
    }
}

.backarrow-setting {
    margin-bottom: 5%;
}

.theme--dark .button-all {
    background-color: #0f0f0f !important;
    color: white !important;
}

.theme--dark .button-all:hover {
    color: #ffffff !important;
    background-color: #262626 !important;
    border: none;
}

.theme--dark .active-button {
    background-color: white !important;
    color: #171817 !important;
    border: none;
    box-shadow: 0px 0px 0px #000000;
}

.active-button {
    background-color: #1E7300 !important;
    color: white !important;
    border: 1px solid #1E7300 !important;
}

.button-all:hover {
    background-color: #1E7300 !important;
    color: white !important;
    // border: 1px solid #1E7300 !important;
}

.booking-card {
    align-items: center;
    display: flex;
    justify-content: space-around;
}

.custom-cardttable {
    display: flex;
    // justify-content: space-between;

}

.arrowtop-tablebk {
    padding: 6px;
    background-color: #e5dddd;
    border-radius: 50%;
    margin: 0px 12px 4px;
    width: 30px;
    height: 27px;
}
.theme--dark .arrowtop-tablebk{
    padding: 6px;
    background-color:white;
    border-radius: 50%;
    margin: 0px 12px 4px;
    width: 30px;
    color: black;
    height: 27px;
}

.custom-tablecard {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15) !important;
    padding: 4% !important;
    border-radius: 10px;
    margin-bottom: 5%;
    background-color: white !important;
}

.theme--dark .custom-tablecard {
    background-color: #02101F !important;
    box-shadow: none !important;
}

.text-cardbox {
    font-size: 12px !important;
    font-weight: 500;
    margin-left: 10px !important;
    margin-bottom: 10px;
    font-family: "SegoeUI";
}

.image-tablebooking {
    height: 40%;
    width: fit-content;
}

.confirmed-text {
    background-color: #E6FFE5 !important;
    color: #329B00 !important;
    display: flex;
    border-radius: 3px;
    padding: 3px 8px;
    font-family: 'SegoeUI';
}

.waiting-text {
    background-color: #fff6a5 !important;
    color: #db9f02 !important;
    border-radius: 5px;
    text-wrap: nowrap;
    font-size: 12px;
    padding: 8px;
}

.imagefor-text {
    margin: 3px 9px 3px 4px;
}

.footer-texttable {
    margin-top: 5%;
    justify-content: space-around;
    padding: 10px;
    background-color: #F9F9F9 !important;
    border-radius: 5px;
    font-weight: 500;
    display: flex;
}

.theme--dark .footer-texttable {
    color: white;
    background-color: #0A1929 !important;
}

.discount-text {
    background-color: #329B00;
    color: white;
    padding: 7px 6px;
    font-size: 12px;
    border-radius: 3px;
}

.discount-textmodal {
    background-color: #329B00;
    color: white;
    padding: 1px 1px;
    border-radius: 3px;
    flex: 1;
}

.spantextdiscount {
    font-weight: 500;
    background-color: none;
}

.imagefor-textreview {
    background-color: #E8EFFF;
    color: #001C63;
    padding: 2px 5px;
    border-radius: 3px;
}

.imagefor-textcancel {
    color: #FF0000;
    padding: 2px 5px;
    border-radius: 3px;
}

.confirmed-textbutton {
    float: right;
    background-color: #E6FFE5 !important;
    // color: #329B00 !important;
    padding: 2px 2px;
    border-radius: 3px;
    border: 1px solid #ffe5f9 !important;
    font-size: 12px;
}

.textjaliso {
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: space-around;
    margin-top: 5%;

}

.button-callrestaurant {
    background-color: #F9F9F9 !important;
    color: #1E7300;
    border: 1px solid #F9F9F9 !important;
    padding: 5px 10px !important;
}

.button-callrestaurant:hover {
    color: #F9F9F9 !important;
    background-color: #b6caff !important;
    border: 1px solid #F9F9F9 !important;
    padding: 5px 10px !important;
}

.button-modify {
    background-color: #F9F9F9 !important;
    color: #0B61A5;
    border: 1px solid #F9F9F9 !important;
    padding: 0px 10px !important;
}

.button-cancelmodal {
    color: #FF0000;
    background-color: #F9F9F9 !important;
    border: 1px solid #F9F9F9 !important;
    padding: 0px 10px !important;
}

.button-cancelmodal:hover {
    background-color: #ffbfbf !important;
    color: rgb(255, 0, 0) !important;
    border: 1px solid #F9F9F9 !important;
    padding: 0px 10px !important;
}

.modalbooking-manage {
    padding: 3%;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    margin-top: 8%;
}

.modal-footertexttable {
    margin-top: 8% 0;
    justify-content: space-around;
    padding: 4%;
    background-color: #F9F9F9;
    border-radius: 5px;
    font-weight: 500;
    display: flex;
    font-size: 16px;
}

.button-optionstablebookingmodal {
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-top: 5%;
    margin-bottom: 5%;
}

.location-card {
    border-radius: 8px;
    padding: 3%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 10%;
    font-family: Arial, sans-serif;
}

.address {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
}

.address p {
    margin: 0;
    color: #333;
    font-size: 12px !important;
    font-weight: 600;
}

.get-direction {
    color: #1E7300;
    text-decoration: none;
    font-weight: bold;
    float: right;
    margin-top: 2%;
}

.get-direction:hover {
    text-decoration: underline;
}

.special-request label {
    display: block;
    margin-bottom: 3%;
    margin-top: 3%;
    color: black;
    font-weight: 600;
}

.special-request input {
    width: 100%;
    padding: 8px;
    border: 1px solid white;
    border-radius: 4px;
    box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.Button-review {
    background-color: #1E7300 !important;
    color: white !important;
    width: 100% !important;
    margin-top: 5%;
    margin-bottom: 5%;
    border: 1px solid #1E7300 !important;
    font-weight: 500;
}

.textfeild-review {
    background-color: #F7F7F7;
    padding: 5%;
    color: #ACACAC;
    margin-top: 5%;
    margin-bottom: 5%;
    border: 1px solid #F7F7F7;
}

.tableBookingImage {
    width: 7%;
    height: 6%;
}

.image-tablebooking {
    height: 26%;
    width: 23%;
    margin-top: 5px;
}

.table-data {
    margin: 0px 0px 0px 12px;
}

.discount-text {
    background-color: #329B00;
    color: white;
    padding: 7px 6px;
    font-size: 12px;
    border-radius: 3px;
}

.waiting-text {
    background-color: #fff6a5 !important;
    color: #db9f02 !important;
    border-radius: 5px;
    text-wrap: nowrap;
    font-size: 14px;
    padding: 7px;
}

.footer-texttable {
    margin-top: 5%;
    padding: 10px;
    background-color: #F9F9F9 !important;
    border-radius: 5px;
    font-weight: 500;
    display: flex;
    font-size: 16px;
}

.tableBookingImage {
    width: 5%;
    margin-top: 2px;
    height: 6%;
}

.responseTime {
    width: 26px;
    height: 19px;
    position: relative;
    bottom: 2px;
}

.responseGuest {
    width: 17px;
    height: 14px;
}

.discount-text {
    font-size: 14px;
    padding: 4px 6px;
}

.imagefor-textcancel {
    background-color: #ffc8c8;
    color: #FF0000;
    padding: 5px;
    border-radius: 3px;
}

.tablearrow-icon {
    float: left;
    margin-right: 2%;
    width: 6%;
    height: 9%;
}

.yourorder-fk {
    .row {
        .no-favourites {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            min-height: 300px;
            padding: 20px;
        }

        .no-favourites img {
            max-width: 100%;
            height: auto;
            margin-bottom: 10px;
        }

        .no-favourites h5 {
            font-family: "SegoeUI";
        }

        .no-favourites button {
            padding: 8px 30px;
            background-color: #9FE870;
            color: black;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            font-size: 16px;
            font-family: "SegoeUI";
            margin-top: 20px;
        }

        .theme--dark .no-favourites button {
            color: white;
        }

        .no-favourites button:hover {
            background-color: #9FE870;
        }
    }
}

@media only screen and (min-width: 1440px) {

    /// <!-- new css add
    .image-tablebooking {
        height: 26%;
        width: 33%;
        margin-top: 5px;
    }

    .modal-footertexttable {
        margin-top: 8% 0;
        justify-content: space-around;
        padding: 4%;
        background-color: #F9F9F9;
        border-radius: 5px;
        font-weight: 500;
        display: flex;
        font-size: 16px;
    }

    .table-data {
        margin: 0;
    }

    .text-cardbox {
        font-size: 12px !important;
        font-weight: 500;
        margin-left: 10px !important;
        margin-bottom: 8px;
    }

    .text-cardbox {
        font-size: 14px !important;
    }

    .responseGuest {
        width: 20px;
        height: 20px;
    }

    .responseTime {
        width: 22px;
        height: 21px;
        position: relative;
        bottom: 0px;
    }

    .tableBookingImage {
        width: 6%;
        margin-top: 2px;
        height: 6%;
    }

    .responseDatetime {
        position: relative;
        right: 22px;
        font-size: 16px;
    }

    .waiting-text {
        background-color: #fff6a5 !important;
        color: #db9f02 !important;
        border-radius: 5px;
        text-wrap: nowrap;
        font-size: 16px;
        padding: 10px;
    }

    .button-optionstablebookingdesktop {
        display: flex;
        flex: 1 1;
        gap: 25px !important;
        margin-top: 5%;
        margin-bottom: 5%;
    }

    .text-cardbox {
        font-size: 18px !important;
        font-weight: 500;
        margin-left: 18px !important;
        margin-bottom: 18px;
        font-size: 20px;
        margin-top: 12px;
    }

    .responseTimeDiv {
        position: relative;
        right: 14px;
    }

    .imagefor-text {
        margin: 3px 8px 5px 3px;
        width: 18px;
    }

    /// new css add -->
    .custom-tablecard {
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15) !important;
        padding: 3% !important;
        border-radius: 10px;
        margin-bottom: 5%;
        box-shadow: none !important;
        background-color: #F8F8F8 !important;
    }

    .footer-texttable {
        font-size: 16px;
        margin-top: 2%;
        justify-content: space-between;
        padding: 10px;
        background-color: white !important;
        border-radius: 5px;
        font-weight: 500;
        display: flex;
    }

    .footer-texttable .responseTime {
        margin: 0px 0px 1px 0px;
    }

    .footer-texttable .responseGuest {
        margin: 0px 3px 4px 0px;
    }

    .tablebooking-arrow {
        padding-top: 4% !important;
        font-family: "SegoeUI-SemiBold";
        margin-top: 5%;
        margin-bottom: 5%;
    }

    .button-optionstablebookingdesktop {
        display: flex;
        flex: 1 1;
        gap: 20px !important;
        margin-top: 7%;
        margin-bottom: 5%;
    }

    .button-all {
        padding: 5px 20px !important;
        background-color: white;
        color: black;
        font-weight: 500;
        font-size: 18px !important;
        margin: 0;
        font-family: "SegoeUI-SemiBold";
    }

    .button-all:hover {
        background-color: #1E7300 !important;
        color: white !important;
    }

    .booktable-button {
        float: right;
        background-color: #1E7300 !important;
        color: white !important;
        border: 1px solid #1E7300 !important;
        padding: 6px 16px !important;
    }

    .bottom-modaladdressdark {
        background-color: #02111f;
        position: fixed;
        bottom: -100%;
        left: 0;
        right: 0;
        margin: auto;
        animation: slide-up 0.5s forwards;
        color: white;
        border-radius: 10px !important;
    }

    .discount-text {
        background-color: #329B00;
        color: white;
        padding: 2px 6px;
        font-size: 15px;
        border-radius: 3px;
    }

    .tablemodalbooking-op {
        .modalbooking-manage {
            .modal-footertexttable {
                margin-top: 5%;
                margin-bottom: 5%;
                justify-content: space-around;
                padding: 2%;
                background-color: #F9F9F9;
                border-radius: 5px;
                display: flex;
                font-size: 12px;

                .calender-div {
                    .model-tableBookingImage {
                        width: 24px;
                        height: 24px;
                        margin: 0px 5px 0px 0px;
                    }
                }
            }
        }
    }

    @keyframes slide-up {
        from {
            bottom: -100% !important;
        }

        to {
            bottom: 50% !important;
        }
    }
}

@media only screen and (min-width: 1193px) and (max-width: 1439px) {

    /// <!-- new css add
    .image-tablebooking {
        height: 26%;
        width: 33%;
        margin-top: 5px;
    }

    .imagefor-text {
        margin: 3px 9px 7px 4px;
        width: 14px;
    }

    .table-data {
        margin: 0;
    }

    .text-cardbox {
        font-size: 16px !important;
        font-weight: 500;
        margin-left: 10px !important;
        margin-bottom: 12px;
        margin-top: 10px;
    }

    .responseGuest {
        width: 17px;
        height: 17px;
    }

    .responseTime {
        width: 18px;
        height: 20px;
        position: relative;
        bottom: 1px;
    }

    .tableBookingImage {
        width: 6%;
        margin-top: 3px;
        height: 6%;
    }

    .responseDatetime {
        position: relative;
        top: 2px;
        right: 19px;
        font-size: 14px;
    }

    .waiting-text {
        background-color: #fff6a5 !important;
        color: #db9f02 !important;
        border-radius: 5px;
        text-wrap: nowrap;
        font-size: 14px;
        padding: 8px;
    }

    .modal-footertexttable {
        margin-top: 8% 0;
        justify-content: space-around;
        padding: 4%;
        background-color: #F9F9F9;
        border-radius: 5px;
        font-weight: 500;
        display: flex;
        font-size: 16px;
    }

    /// new css add -->
    .custom-tablecard {
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15) !important;
        padding: 3% !important;
        border-radius: 10px;
        margin-bottom: 5%;
        box-shadow: none !important;
        background-color: #F8F8F8 !important;
    }

    .footer-texttable {
        font-size: 14px;
        margin-top: 2%;
        justify-content: space-between;
        padding: 10px;
        background-color: white !important;
        border-radius: 5px;
        font-weight: 500;
        display: flex;
    }

    .footer-texttable .responseTime {
        margin: 0px 0px 1px 0px;
    }

    .footer-texttable .responseGuest {
        margin: 0px 3px 4px 0px;
    }

    .tablebooking-arrow {
        padding-top: 4% !important;
        // font-size: x-large;
        font-family: "SegoeUI-SemiBold";
    }

    .button-optionstablebookingdesktop {
        display: flex;
        flex: 1 1;
        gap: 20px !important;
        margin-top: 5%;
        margin-bottom: 5%;
    }

    .button-all {
        padding: 5px 14px !important;
        // background-color: #F8F8F8 !important;
        background-color: white;
        // border: none !important;
        color: black;
        font-weight: 500;
        font-size: 16px !important;
        margin: 0;
        font-family: "SegoeUI-SemiBold";
    }

    .button-all:hover {
        background-color: #1E7300 !important;
        color: white !important;
    }

    .booktable-button {
        float: right;
        background-color: #1E7300 !important;
        color: white !important;
        border: 1px solid #1E7300 !important;
        padding: 6px 16px !important;
    }

    .bottom-modaladdress {
        position: fixed;
        top: 25%;
        left: 12.5;
        margin: 0 auto;
        animation: fade-in 0.5s forwards;
        z-index: 1050;
    }

    .bottom-modaladdressdark {
        background-color: #02111f;
        position: fixed;
        bottom: -100%;
        left: 0;
        right: 0;
        margin: auto;
        animation: slide-up 0.5s forwards;
        color: white;
        border-radius: 10px !important;
    }

    .discount-text {
        background-color: #329B00;
        color: white;
        padding: 2px 10px;
        font-size: 14px;
        border-radius: 5px;
    }

    .tablemodalbooking-op {
        .modalbooking-manage {
            .modal-footertexttable {
                margin-top: 5%;
                margin-bottom: 5%;
                justify-content: space-around;
                padding: 2%;
                background-color: #F9F9F9;
                border-radius: 5px;
                display: flex;
                font-size: 16px;

                .calender-div {
                    .model-tableBookingImage {
                        width: 16px;
                        height: 16px;
                        margin: 0px 5px 0px 0px;
                    }
                }
            }
        }
    }

    @keyframes slide-up {
        from {
            bottom: -100% !important;
        }

        to {
            bottom: 50% !important;
        }
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {

    /// <!-- new css add
    .image-tablebooking {
        height: 26%;
        width: 33%;
        margin-top: 5px;
    }

    .imagefor-text {
        margin: 3px 9px 7px 4px;
        width: 14px;
    }

    .table-data {
        margin: 0;
    }

    .text-cardbox {
        font-size: 14px !important;
        font-weight: 500;
        margin-left: 10px !important;
        margin-bottom: 6px;
        margin-top: 5px;
    }

    .responseGuest {
        width: 12px;
        height: 12px;
        position: relative;
        top: 2px;
    }

    .responseTime {
        width: 12px;
        height: 12px;
        position: relative;
        bottom: 2px;
    }

    .tableBookingImage {
        width: 6%;
        margin-top: 3px;
        height: 6%;
    }

    .responseDatetime {
        position: relative;
        top: 3px;
        right: 8px;
        font-size: 10px;
    }

    .waiting-text {
        background-color: #fff6a5 !important;
        color: #db9f02 !important;
        border-radius: 5px;
        text-wrap: nowrap;
        font-size: 10px;
        padding: 7px 5px;
    }

    /// new css add -->
    .custom-tablecard {
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15) !important;
        padding: 3% !important;
        border-radius: 10px;
        margin-bottom: 5%;
        box-shadow: none !important;
        background-color: #F8F8F8 !important;
    }

    .footer-texttable {
        font-size: 10px;
        margin-top: 2%;
        justify-content: space-between;
        padding: 10px;
        background-color: white !important;
        border-radius: 5px;
        font-weight: 500;
        display: flex;
    }

    .footer-texttable .responseTime {
        margin: 0px 0px 1px 0px;
    }

    .footer-texttable .responseGuest {
        margin: 0px 1px 3px 0px;
    }

    .responseGuestDiv span {
        margin: 0px 0px 0px 4px;
        position: relative;
        top: 1px;
    }


    .tablebooking-arrow {
        padding-top: 4% !important;
        // font-size: x-large;
        font-family: "SegoeUI-SemiBold";
    }

    .button-optionstablebookingdesktop {
        display: flex;
        flex: 1 1;
        gap: 20px !important;
        margin-top: 5%;
        margin-bottom: 5%;
    }

    .button-all {
        padding: 5px 14px !important;
        // background-color: #F8F8F8 !important;
        background-color: white;
        // border: none !important;
        color: black;
        font-weight: 500;
        font-size: 16px !important;
        margin: 0;
        font-family: "SegoeUI-SemiBold";
    }

    .button-all:hover {
        background-color: #1E7300 !important;
        color: white !important;
    }

    .booktable-button {
        float: right;
        background-color: #1E7300 !important;
        color: white !important;
        border: 1px solid #1E7300 !important;
        padding: 6px 16px !important;
    }


    .bottom-modaladdressdark {
        background-color: #02111f;
        position: fixed;
        bottom: -100%;
        left: 0;
        right: 0;
        margin: auto;
        animation: slide-up 0.5s forwards;
        color: white;
        border-radius: 10px !important;
    }

    .discount-text {
        background-color: #329B00;
        color: white;
        padding: 2px 5px;
        font-size: 10px;
        border-radius: 3px;
    }

    .responseTimeDiv {
        position: relative;
        top: 3px;
    }

    .modal-footertexttable {
        margin-top: 8% 0;
        justify-content: space-around;
        padding: 4%;
        background-color: #F9F9F9;
        border-radius: 5px;
        font-weight: 500;
        display: flex;
        font-size: 16px;
    }

    @keyframes slide-up {
        from {
            bottom: -100% !important;
        }

        to {
            bottom: 50% !important;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
    .button-all {
        font-size: 16px !important;
        font-family: "SegoeUI-SemiBold";
    }

    .modal-footertexttable {
        margin-top: 8% 0;
        justify-content: space-around;
        padding: 4%;
        background-color: #F9F9F9;
        border-radius: 5px;
        font-weight: 500;
        display: flex;
        font-size: 16px;
    }

    .image-tablebooking {
        height: 26%;
        width: 23%;
        margin-top: 5px;
    }

    .confirmed-text {
        background-color: #E6FFE5 !important;
        color: #329B00 !important;
        display: flex;
        border-radius: 3px;
        padding: 4px;
    }

    .text-cardbox {
        font-size: 18px !important;
        margin-bottom: 25px;
    }

    .responseDatetime {
        position: relative;
        right: 32px;
        top: 2px;
    }

    .responseDatetimeDiv {
        position: relative;
        right: 15px;
    }

    .footer-texttable {
        margin-top: 5%;
        padding: 10px;
        background-color: #F9F9F9 !important;
        border-radius: 5px;
        font-weight: 500;
        display: flex;
        font-size: 16px;
    }

    .responseTimeDiv {
        position: relative;
        right: 28px;
    }

    .responseGuestDiv {
        position: relative;
        right: 18px;
    }

    .tableBookingImage {
        width: 4%;
        margin-top: 2px;
        height: 6%;
    }

    .table-data {
        margin: 10px 0px 0px 12px;
    }

}

@media only screen and (max-width: 768px) {
    .bottom-modaladdress {
        position: fixed;
        top: 25%;
        left: 0;
        margin: 0 auto;
        animation: fade-in 0.5s forwards;
        z-index: 1050;
    }

    .button-all {
        font-size: 16px !important;
        font-family: "SegoeUI-SemiBold";
    }

    .table-data {
        margin: 7px 0px 0px 0px;
    }

    .responseGuest {
        width: 25px;
        height: 19px;
    }

    .image-tablebooking {
        height: 26%;
        width: 23%;
        margin-top: 5px;
    }

    .confirmed-text {
        background-color: #E6FFE5 !important;
        color: #329B00 !important;
        display: flex;
        border-radius: 3px;
        padding: 4px;
    }

    .text-cardbox {
        font-size: 18px !important;
        margin-bottom: 30px;

    }

    .responseDatetime {
        position: relative;
        right: 28px;
        top: 2px;
    }


    .responseDatetimeDiv {
        position: relative;
        right: 15px;
    }

    .footer-texttable {
        margin-top: 5%;
        padding: 10px;
        background-color: #F9F9F9 !important;
        border-radius: 5px;
        font-weight: 500;
        display: flex;
        font-size: 16px;
    }

    .responseTimeDiv {
        position: relative;
        right: 28px;
    }

    .responseGuestDiv {
        position: relative;
        right: 18px;
    }

    .tableBookingImage {
        width: 5%;
        margin-top: 2px;
        height: 6%;
    }

    .discount-text {
        font-size: 16px;
        padding: 5px 11px;
    }
}

@media only screen and (max-width: 620px) {
    .theme--dark .active-button {
        background-color: white !important;
        color: #171817 !important;
        border: none;
    }
    .tablebooking-arrow {
        padding: 20px 0px 10px;
    }

    .bottom-modaladdress {
        position: fixed;
        top: 25%;
        left: 0;
        margin: 0 auto;
        animation: fade-in 0.5s forwards;
        z-index: 1050;
    }

    .tablemodalbooking-op {
        .modalbooking-manage {
            .modal-footertexttable {
                margin-top: 5%;
                margin-bottom: 5%;
                justify-content: space-around;
                padding: 2%;
                background-color: #F9F9F9;
                border-radius: 5px;
                display: flex;
                font-size: 12px;

                .calender-div {
                    .model-tableBookingImage {
                        width: 16px;
                        height: 16px;
                        margin: 0px 5px 0px 0px;
                    }
                }
            }
        }
    }

    .button-all {
        font-size: 16px !important;
    }

    .text-cardbox {
        font-size: 16px !important;
    }

    .responseDatetime {
        position: relative;
        right: 22px;
    }

    .discount-text {
        font-size: 16px;
        padding: 2px 6px;
    }

    .tablearrow-icon {
        float: left;
        margin-right: 2%;
        width: 6%;
        height: 3%;
    }

    .responseGuest {
        width: 23px;
        height: 18px;
        margin-bottom: 3px;
    }

    .footer-texttable {
        margin-top: 5%;
        padding: 10px;
        background-color: #F9F9F9 !important;
        border-radius: 5px;
        font-weight: 500;
        display: flex;
        font-size: 16px;
    }

    .tableBookingImage {
        width: 6%;
        height: 6%;
    }

    .responseGuestDiv {
        position: relative;
        right: 2px;
    }

    .responseTimeDiv {
        position: relative;
        right: 14px;
    }

    .responseGuestDiv {
        position: relative;
        right: 2px;
        top: 2px;
    }
}

@media only screen and (max-width: 530px) {
    .theme--dark .active-button {
        background-color: white !important;
        color: #171817 !important;
        border: none;
    }
    .bottom-modaladdress {
        position: fixed;
        top: 25%;
        left: 0;
        margin: 0 auto;
        animation: fade-in 0.5s forwards;
        z-index: 1050;
    }

    .button-all {
        font-size: 16px !important;
    }

    .responseGuestDiv {
        position: relative;
        right: 2px;
        top: 2px;
    }

    .responseTimeDiv {
        position: relative;
        right: 7px;
    }

    .text-cardbox {
        font-size: 20px !important;
        margin-bottom: 16px;
    }

    .responseDatetime {
        position: relative;
        right: 22px;
    }

    .discount-text {
        font-size: 16px;
        padding: 2px 6px;
    }

    .tablearrow-icon {
        float: left;
        margin-right: 2%;
        width: 6%;
        height: 3%;
    }

    .footer-texttable {
        margin-top: 5%;
        padding: 10px;
        background-color: #F9F9F9 !important;
        border-radius: 5px;
        font-weight: 500;
        display: flex;
        font-size: 16px;
    }
}

@media only screen and (max-width: 500px) {
    .theme--dark .active-button {
        background-color: white !important;
        color: #171817 !important;
        border: none;
    }
    .text-cardbox {
        font-size: 16px !important;
        margin-bottom: 16px;
    }

    .responseDatetime {
        position: relative;
        right: 12px;
    }

    .tableBookingImage {
        width: 7%;
        height: 6%;
    }

    .image-tablebooking {
        height: 26%;
        width: 35%;
        // margin-top: 14px;
    }

    .table-data {
        margin: 0px 0px 0px 5px;
    }

    .tablearrow-icon {
        float: left;
        margin-right: 2%;
        width: 6%;
        height: 6%;
    }

    .responseGuestDiv {
        position: relative;
        right: 2px;
        top: 2px;
    }

    .image-tablebooking {
        height: 26%;
        width: 30%;
        margin-top: 0px;
    }
}

@media only screen and (max-width: 425px) {
    .theme--dark .yourorder-fk {
        background-color: #0a1929 !important;
        color: white;
        height: auto !important;
        padding-bottom: 20px;
    }
    .theme--dark .active-button {
        background-color: white !important;
        color: #171817 !important;
        border: none;
    }

    .image-tablebooking {
        height: 26%;
        width: 23%;
        margin-top: 0px;
    }

    .responseDatetime {
        position: relative;
        right: 10px;
        top: 2px;
        font-size: 14px;
    }

    .responseGuestDiv {
        position: relative;
        right: 2px;
        top: 2px;
        font-size: 14px;
    }

    .booking-btn {
        font-size: 18px;
        font-family: "SegoeUI-SemiBold";
        // margin-left: 10px !important;
    }

    .responseTimeDiv {
        position: relative;
        right: 4px;
        top: 2px;
        font-size: 14px;
    }

    .text-cardbox {
        font-size: 16px !important;
        margin-bottom: 18px;
    }

    .table-data {
        margin: 0px 0px;
    }

    .button-all {
        font-size: 14px !important;
    }

    .image-tablebooking {
        height: 29%;
        width: 30%;
    }

    .waiting-text {
        background-color: #fff6a5 !important;
        color: #db9f02 !important;
        border-radius: 5px;
        text-wrap: nowrap;
        font-size: 14px;
        padding: 7px;
    }

    .footer-texttable {
        margin-top: 5%;
        padding: 10px;
        background-color: #F9F9F9 !important;
        border-radius: 5px;
        font-weight: 500;
        display: flex;
        font-size: 12px;
    }

    .tableBookingImage {
        width: 6%;
        height: 6%;
    }

    .responseTime {
        width: 21px;
        height: 16px;
        position: relative;
        bottom: 2px;
    }

    .responseGuest {
        width: 17px;
        height: 14px;
    }

    .discount-text {
        font-size: 14px;
        padding: 0px 6px;
    }

    .imagefor-textcancel {
        background-color: #FFEAF7;
        color: #FF0000;
        padding: 5px 3px;
        border-radius: 3px;
    }

    .tablearrow-icon {
        float: left;
        margin-right: 2%;
        width: 5%;
        height: 9%;
        margin-top: 5px;
    }
}

@media only screen and (max-width: 375px) {
    .theme--dark .yourorder-fk {
        background-color: #0a1929 !important;
        color: white;
        height: auto !important;
        padding-bottom: 20px;
    }
    .theme--dark .active-button {
        background-color: white !important;
        color: #171817 !important;
        border: none;
    }

    .bottom-modaladdress {
        position: fixed;
        top: 25%;
        left: 0;
        margin: 0 auto;
        animation: fade-in 0.5s forwards;
        z-index: 1050;
    }

    .button-all {
        font-size: 14px !important;
    }

    .tablearrow-icon {
        float: left;
        margin-right: 2%;
        width: 6%;
        height: 9%;
        margin-top: 6px;
    }

    .booking-btn {
        font-size: 18px;
        font-family: "SegoeUI-SemiBold";
        // margin-left: 10px;
    }

    .table-data {
        margin: 0px 0px;
    }

    .text-cardbox {
        font-size: 14px !important;
        margin-bottom: 10px;
    }

    .image-tablebooking {
        width: 30%;
        height: 29%;
        margin-top: 0px;
    }

    .waiting-text {
        background-color: #fff6a5 !important;
        color: #db9f02 !important;
        border-radius: 5px;
        text-wrap: nowrap;
        font-size: 10px;
        padding: 7px;
    }

    .footer-texttable {
        margin-top: 5%;
        padding: 10px;
        background-color: #F9F9F9 !important;
        border-radius: 5px;
        font-weight: 500;
        display: flex;
        font-size: 12px;
    }

    .tableBookingImage {
        width: 7%;
        height: 6%;
    }

    .responseTime {
        width: 20px;
        height: 15px;
        margin-bottom: -1px;
    }

    .responseGuestDiv {
        position: relative;
        right: 2px;
        top: 2px;
    }

    .responseGuest {
        width: 17px;
        height: 14px;
    }

    .discount-text {
        font-size: 12px;
        padding: 2px 5px;
    }

    .imagefor-textcancel {
        width: 23%;
        padding: 4px 4px 5px 5px;
    }

    .responseDatetime {
        position: relative;
        right: 4px;
        top: 2px;
    }

    .booktable-button {
        float: right;
        background-color: #1E7300 !important;
        color: white !important;
        border: 1px solid #1E7300 !important;
        padding: 2px 4px;
        font-size: 12px;
        font-family: "SegoeUI-SemiBold";
    }
}

@media only screen and (max-width: 325px) {
    .theme--dark .yourorder-fk {
        background-color: #0a1929 !important;
        color: white;
        height: auto !important;
        padding-bottom: 20px;
    }
    .theme--dark .active-button {
        background-color: white !important;
        color: #171817 !important;
        border: none;
    }

    .bottom-modaladdress {
        position: fixed;
        top: 25%;
        left: 0;
        margin: 0 auto;
        animation: fade-in 0.5s forwards;
        z-index: 1050;
    }

    .responseDatetime {
        position: relative;
        right: 1px;
    }

    .responseTime {
        width: 20px;
        height: 15px;
        margin-bottom: 0px;
    }

    .image-tablebooking {
        width: 32%;
        height: 29%;
        margin-top: 0px;
    }

}

@media only screen and (max-width: 320px) {
    .theme--dark .yourorder-fk {
        background-color: #0a1929 !important;
        color: white;
        height: auto !important;
        padding-bottom: 20px;
    }

    .booktable-button {
        float: right;
        background-color: #1E7300 !important;
        color: white !important;
        border: 1px solid #1E7300 !important;
        padding: 2px 4px;
        padding: 4px 6px;
        font-size: 12px;
    }

    .responseDatetime {
        position: relative;
        right: 10px;
        top: 2px;
    }

    .footer-texttable .responseGuestDiv {
        position: relative;
        top: 2px;
    }

    .booking-btn {
        font-size: 12px;
        font-family: "SegoeUI-SemiBold";
        // margin-left: 10px;
    }

    .button-all {
        font-size: 10px !important;
    }

    .table-data {
        margin: 0px 0px 0px 10px;
    }

    .text-cardbox {
        font-size: 13px !important
    }

    .image-tablebooking {
        width: 32%;
        height: 30%;
        object-fit: cover;
    }

    .tablearrow-icon {
        float: left;
        margin-right: 2%;
        margin-top: 6px;
        width: 7%;
        height: 6%;
    }

    .footer-texttable {
        font-size: 10px;
        padding: 5px;

        .tableBookingImage {
            width: 6%;
            height: 6%;
        }

        .responseTime {
            width: 13px;
        }

        .responseGuest {
            width: 50%;
            height: 64%;
        }

        .discount-text {
            font-size: 10px;
            padding: 2px 3px;
        }
    }

    .waiting-text {
        background-color: #fff6a5 !important;
        color: #db9f02 !important;
        border-radius: 5px;
        text-wrap: nowrap;
        font-size: 10px;
        padding: 6px 5px;
    }

    .imagefor-text {
        margin: 3px 9px 3px 4px;
    }

    .imagefor-textcancel {
        width: 23%;
    }

    .tablemodalbooking-op {
        .modalbooking-manage {
            .modal-footertexttable {
                margin-top: 5%;
                margin-bottom: 5%;
                justify-content: space-around;
                padding: 2%;
                background-color: #F9F9F9;
                border-radius: 5px;
                display: flex;
                font-size: 12px;

                .calender-div {
                    .model-tableBookingImage {
                        width: 16px;
                        height: 16px;
                        margin: 0px 5px 0px 0px;
                    }
                }

                .model-responseDatetime {
                    width: 6%;
                    height: 6%;
                }

                .model-responseGuestDiv {
                    position: relative;
                    top: 2px;
                }

                .model-discount-text {
                    font-size: 10px;
                }
            }
        }
    }

    .textjaliso {
        font-size: 15px;
        font-weight: 500;
        display: flex;
        justify-content: space-around;
        margin-top: 5%;

        button {
            width: 124px;
            height: 40px;
        }

    }

    .text-cardbox {
        font-size: 12px !important;
        font-weight: 500;
        margin-left: 1px !important;
        margin-bottom: 10px;
        font-family: "SegoeUI-SemiBold";
    }

    .confirmed-btn {
        background-color: #1E7300;
    }
    .theme--dark .active-button {
        background-color: white !important;
        color: #171817 !important;
        border: none;
    }

    .reject-btn {
        background-color: #ff9595;
    }

}