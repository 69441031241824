.homescreen-app {
    display: flex;
    flex-direction: column;     
    min-height: 100vh;
    margin: 0;
    overflow: hidden;
    height: 100vh;

    /* Prevents scrolling if necessary */
    .homescreen-app-skip {
        position: absolute;
        right: 15px;
        background: #fff;
        top: 15px;
        border-radius: 50px;
        width: 80px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-family: 'Segoe-UI-Semibold-600';
    }

    .homescreen-app-img {
        width: 100%;
        height: auto;
        height: 75vh;
        /* Default height */
        object-fit: cover;
        /* Ensure the image scales properly */
    }

    .homescreen-app-content {
        flex: 1;
        padding-bottom: 20px;
        background-color: #fff;
        height: auto;
        height: 25vh;
        /* Ensure it occupies the rest of the space */

        .homescreen-app-text {
            text-align: center;
            font-size: 20px;
            font-weight: 700;
            margin: 12px;
            font-family: 'Segoe-UI-Semibold-600';
        }

        .homescreen-app-button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 90%;
            padding: 15px;
            margin: 0px 20px;
            background-color: #000 !important;
            border-color: #000;
            font-size: 22px;
        }
    }
}

/* Fix for iOS Devices (e.g., iPhone SE) */
@media screen and (max-width: 320px) and (max-width: 375px) {
    .homescreen-app-top {
        height: calc(60vh - 10px);
        /* Adjust height for smaller screens */
    }

    .homescreen-app-content {
        height: calc(40vh - 10px);
        padding-bottom: 20px;
    }
}

@media screen and (max-width: 375px) and (max-height: 667px) {

    /* iPhone SE (3rd Gen) specific fix */
    .homescreen-app-top {
        height: calc(70vh - 10px);
        /* Reduce image height for smaller screens */
    }

    .homescreen-app-content {
        height: calc(30vh - 10px);
        /* Adjust content area accordingly */
        padding-bottom: 20px;
        /* Ensure enough spacing for content */
    }
}