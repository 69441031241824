@import "../../../../../common/assets/scss/main.scss";

//AddressMyAccount 
.theme--dark .address-module {
  background-color: #0a1929;
  height: 100vh;
  color: white;
}

.address-book {
  padding: 12px 0px;
 height: 100vh;
  .no-address-img {
    padding: 130px 0px 25px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 250px;
    }

    h3 {
      font-family: map-get($map: $segoe-fonts, $key: ui-bold);
      color: $theme-dark1;
      font-size: 24px;
      margin: 10px 0px;
      text-align: center;

      @include themed() {
        color: t("light-text");
      }
    }
  }

  .address-box {
    border: 1px solid $gray-bg;
    margin-bottom: 30px;
    border-radius: 5px;

    @include themed() {
      border-color: t("b-color");
    }

    &:hover {
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

      @include themed() {
        box-shadow: t("none");
      }
    }

    .table-responsive {
      table {
        margin: 0px;

        tbody {
          tr {
            &:first-child {
              td {
                &:nth-child(1) {
                  width: 15%;
                  background-color: #eef6ff;
                  border-radius: 5px 0px 0px 5px;

                  @include themed() {
                    background-color: t("hoverbg");
                  }
                }

                &:nth-child(2) {
                  width: 25%;
                  border-radius: 5px 0px 0px 5px;
                }

                &:nth-child(3) {
                  width: 35%;
                }

                &:nth-child(4) {
                  width: 25%;
                }
              }
            }

            td {
              border: none;
              padding: 12px;
              vertical-align: middle;

              .img-box {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 10px 0px;

                .address-type {
                  background-color: transparent;
                  padding: 0px;
                  margin: 10px 0px 0px 0px;
                  font-family: map-get($map: $segoe-fonts, $key: ui-bold);
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  gap: 5px;
                  border: none;
                  outline: none;

                  &.home {
                    color: #32c070;

                    svg {
                      color: #32c070;
                    }
                  }

                  &.office {
                    color: #276cf6;

                    svg {
                      color: #276cf6;
                    }
                  }

                  &.other {
                    color: #d92662;

                    svg {
                      color: #d92662;
                    }
                  }

                  // &:hover{

                  // }
                  svg {
                    cursor: pointer;
                    color: $theme-dark1;
                  }
                }
              }

              .primary-btn {
                background-color: #204356;
                padding: 9px;
                display: block;
                text-align: center;
                margin: 0px -12px -12px -12px;
                border-radius: 0px 0px 0px 5px;
                color: #fff;
                font-family: map-get($map: $segoe-fonts, $key: ui-semibold);

                @include themed() {
                  background-color: t("green2");
                }
              }

              .text-group {
                span {
                  font-family: map-get($map: $segoe-fonts, $key: ui-semibold);
                  color: #939393;
                  font-size: 14.5px;

                  @include themed() {
                    color: t("hovercolor");
                  }
                }

                p {
                  margin: 0px;
                  font-family: map-get($map: $segoe-fonts, $key: ui-semibold);
                  color: $theme-dark1;
                  font-size: 16px;

                  @include themed() {
                    color: t("whitecolor");
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .add-address-div {
    display: flex;
    justify-content: center;
    margin: 10px 0px;

    .addr-add-btn {
      color: #173303;
      background-color: #9fe870;
      padding: 10px 30px;
      text-decoration: none;
      font-family: map-get($map: $segoe-fonts, $key: ui-semibold);
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      font-size: 17px;
      border: none;
      outline: none;

      svg {
        color: #173303;
        fill: #173303;
      }
    }
  }

  .address-box-mob {
    margin-bottom: 20px;
    border: 1px solid #eef6ff;
    border-radius: 5px;

    @include themed() {
      border-color: t("green2");
    }

    .heading-box {
      display: flex;
      flex-direction: row;

      .img-box {
        background-color: #eef6ff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 40%;
        padding: 10px 0px 0px 0px;
        height: 150px;
        border-radius: 5px 0px 0px 5px;

        @include themed() {
          background-color: t("hoverbg");
        }

        button.address-type {
          background-color: transparent;
          padding: 0px;
          margin: 10px 0px 0px 0px;
          font-family: map-get($map: $segoe-fonts, $key: ui-bold);
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 5px;
          border: none;
          outline: none;
          font-size: 15px;

          &.home {
            color: #32c070;

            svg {
              color: #32c070;
            }
          }

          &.office {
            color: #276cf6;

            svg {
              color: #276cf6;
            }
          }

          &.other {
            color: #d92662;

            svg {
              color: #d92662;
            }
          }

          // &:hover{

          // }
          svg {
            cursor: pointer;
            color: $theme-dark1;
          }
        }

        .primary-btn {
          background-color: #204356;
          padding: 6px;
          display: block;
          text-align: center;
          margin: 0px;
          color: #fff;
          font-family: map-get($map: $segoe-fonts, $key: ui-semibold);
          width: 100%;
          font-size: 14px;
          margin-top: 5px;
          border-radius: 0px 0px 0px 5px;
          margin-bottom: -1px;

          @include themed() {
            background-color: t("green2");
          }
        }
      }

      .name-number {
        width: 60%;
        padding-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 10px !important;

        span {
          border-bottom: 0px;
          margin: 0px;
          font-family: map-get($map: $segoe-fonts, $key: ui-semibold);
          color: $theme-dark1;
          font-size: 14px;

          @include themed() {
            color: t("whitecolor");
          }
        }
      }
    }
  }
}

.arrow-icon {
  float: left;
  margin-right: 5%;
  width: 9%;
  height: 9%;
  margin-bottom: 10%;
}

.arrow-iconaddress {
  float: left;
  width: 25px !important;
  height: 25px !important;
  margin-right: 1%;
  margin-bottom: auto;
}

.arrow-address-text {
  padding-top: 5% !important;
  padding: 1% !important;
  font-family: "SegoeUI-SemiBold";
}

.theme--dark .arrow-address-text {
  color: white;
}

.toggle-buttonaddress {
  border: 1px solid #B0B4C0 !important;
  // background-color: transparent;
  padding: 1px 10px;
  cursor: pointer;
  color: black !important;
  font-weight: 500;
  background-color: white !important;
}

.toggle-buttonaddressdark {
  color: white !important;
  background-color: #02111f;
  border: 1px solid #B0B4C0 !important;
}

.toggle-buttonaddressdark:hover {
  background-color: white !important;
  color: black !important;
  border: 1px solid white;
}

.theme--dark .modal-payments {
  background-color: #02111f;
  color: white;
}

.bottom-modaladdress {
  position: fixed;
  bottom: -100%;
  left: 0;
  right: 0;
  margin: auto;
  animation: slide-up 0.5s forwards;
}

.theme--dark .bottom-modaladdress {
  background-color: #02111f;
  position: fixed;
  bottom: -100%;
  left: 0;
  right: 0;
  margin: auto;
  animation: slide-up 0.5s forwards;
  color: white;
  border-top-left-radius: 10%;
  border-top-right-radius: 10%;
}

@keyframes slide-up {
  from {
    bottom: -100% !important;
  }

  to {
    bottom: 0% !important;
  }
}

.toggle-buttonaddress:hover {
  background-color: #006B04 !important;
  border: 1px solid #006B04 !important;
  color: white;
}

.button-group {
  display: flex;
  justify-content: space-between;

}

.text-content {
  flex: 1;
}

.payments-order {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  position: relative;
  padding: 8px;
  margin-top: 4% !important;
  margin-bottom: 3% !important;
}

.theme--dark .payments-order {
  background-color: #02111f;
  color: white;
  box-shadow: none;
}

.food-payments {
  display: flex;
  align-items: center;
  padding: 2px;
}

.form-label {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.text-fielddelivery {
  font-weight: 500;
  font-size: 12px;
  color: #767C8A;
  border-bottom: 2px dotted #767C8A !important;
  margin-left: 5%;
  width: 90%;

}

.savebuttondiv {
  text-align: center !important;
}

.save-addressbutton {
  background-color: #006B04 !important;
  color: white;
  font-weight: 500;
  border: #006B04 !important;
  width: 50%;
  margin-top: 5%;
  margin-bottom: 5%;
  container: 5px;
}

.add-detailsbutton {
  background-color: #006B04 !important;
  color: white !important;
  width: 100%;
  font-weight: 500;
  border: #006B04 !important;
}

.address-spantext {
  float: right;
  color: #006B04;
  font-weight: 500;
  font-size: 18px;
  margin-top: 2px;
}

.headerfivetag {
  flex: 1;
  font-weight: 400;
}

.ptag-addresspop {
  color: #767C8A;
}

.hsix-tag {
  color: #306DD8;

}

.address-tittle {
  color: #006B04;
  font-weight: 500;
  font-family: "SegoeUI"
}

.arrow-confirm-text {
  margin-top: 5%;
  padding: 5%;
  font-size: 18px;
  font-weight: 500;

}

.addressaddicon {
  width: 25px;
  height: 25px;
}

.confirm-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  padding: 5%;
}



.textinputweight .MuiInputBase-input {
  font-weight: 600;
  font-size: 15px;
  background-color: white;
  color: black;
  border-radius: 10px !important;
  // padding: 5%;
}

.textinputweight .MuiOutlinedInput-root {
  border-radius: 10px !important;
  margin-bottom: 5px !important;
}

.textinputweightdark .MuiOutlinedInput-root {
  border-color: #D1D4DB;
  color: white;
  font-weight: 400;
  border-radius: 10px !important;
  margin-bottom: 5px !important;
}

.textinputweightdark .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #D1D4DB;
  color: white;
  font-weight: 300;
}

.textinputweightdark .MuiInputLabel-root {
  color: #D1D4DB;
}


.line-container {
  display: flex;
  align-items: center;
  width: 100%;
  margin:5px !important;
  // margin-top: 5%;
  // margin-bottom: 5%;
}

.line {
  border-top: 0.5px solid #767C8A;
  flex-grow: 1;
}

.theme--dark.line {
  border-top: 0.5px solid white;
}

.text {
  margin: 0 15px;
  font-size: 16px;
  color: #767C8A;
  text-align: center;
  font-weight: 500;
}

.theme--dark .text {
  color: white;
}

.addressediticon {
  padding-top: 7px;
}

.primaryiconhome {
  font-size: 60px;
  padding-top: 10px;
  fill: #173303;
}

.theme--dark .primaryiconhome {
  font-size: 60px;
  padding-top: 10px;
  fill: white;
}

.primaryiconhomeadress {
  font-size: 60px;
  padding-top: 10px;
  fill: #173303;
}

.theme--dark .primaryiconhomeadress {
  font-size: 60px;
  padding-top: 10px;
  fill: white;
}

.address-icon {
  display: flex !important;
  justify-content: space-around !important;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15) !important;
  padding: 10px !important;
  background-color: white !important;
  border-radius: 5px;
  margin-top: 6%;
  .address-type .office {
    display: flex;
    flex-direction: column;
  }
}
.address-namenumber {
  font-size: 16px;
  color: #B0B4C0;
  margin-bottom: 2px;
  font-family: "SegoeUI";
}

.address-numberupdate {
  font-family: "SegoeUI";
}

.home-address {
  color: #006B04;
  font-weight: 500;
  font-size: 16px;
}
.home-addresshome{
   color: #006B04;
  font-weight: 500;
  font-size: 16px;
  margin-left: 6px;
}

.update-btnv2 {
  background-color: #006B04 !important;
}

.delete-btnv2 {
  background-color: #B0B4C0 !important;
}

.enter-complete {
  flex: 1;
  font-size: 20px !important;
  font-weight: 500;
}

.close-btnv2 {
  float: right;
  background-color: white;
  color: black;
  border: 1px solid white !important;
}

@media only screen and (min-width: 768px) and (max-width: 1600px) {

  .arrow-address-text {
    padding-top: 3% !important;
    border-bottom: none !important;
    font-family: "SegoeUI-SemiBold";
  }

  .payments-order {
    margin-top: 3% !important;
    margin-bottom: 5% !important;
  }

  .textinputweight .MuiInputBase-input {
    font-weight: 600;
    font-size: 15px;
    background-color: white;
    color: black;
    border-radius: 10px !important;
    padding: 5% !important;
  }
}


.heading-tag {
  font-family: "SegoeUI-SemiBold";
}

.arrowtop-iconaddress {
  background-color: #e5dddd;
  padding: 4px;
  border-radius: 50px;
  width: 25px !important;
  height: 25px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  margin-right: 2%;
}

.bottom-modaladdress {
  position: fixed;
  bottom: -100%;
  left: 0;
  right: 0;
  margin: auto;
  animation: slide-up 0.5s forwards;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

.theme--dark .bottom-modaladdress {
  background-color: #02111f;
  position: fixed;
  bottom: -100%;
  left: 0;
  right: 0;
  margin: auto;
  animation: slide-up 0.5s forwards;
}